import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";

const Ministries = () => {
  const [ministries, setMinistries] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedMinistry, setSelectedMinistry] = useState(null);
  const [formData, setFormData] = useState({ name: "", address: "" });
  const [file, setFile] = useState(null);

  useEffect(() => {
    // Sample data for testing
    const sampleData = [
      {
        id: 1,
        name: "Ministry of Agriculture",
        address: "ilawe-ekiti,ekiti state",
      },
      {
        id: 2,
        name: "Ministry of Education",
        address: "Ado-Ekiti,Ekiti state",
      },
      { id: 3, name: "Ministry of Finance", address: "Ado-Ekiti, Ekiti state" },
    ];
    setMinistries(sampleData);
  }, []);

  // Open modal & populate form with selected ministry's data
  const handleEditClick = (ministry) => {
    setSelectedMinistry(ministry);
    setFormData({ name: ministry.name, address: ministry.address });
    setShowModal(true);
  };

  const handleCreateClick = () => {
    setSelectedMinistry(null);
    setFormData({ name: "", address: "" });
    setShowModal(true);
  };

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Save updates & close modal
  const handleSave = () => {
    if (selectedMinistry) {
      setMinistries(
        ministries.map((ministry) =>
          ministry.id === selectedMinistry.id
            ? { ...ministry, name: formData.name, address: formData.address }
            : ministry
        )
      );
    } else {
      setMinistries([
        ...ministries,
        { id: ministries.length + 1, ...formData },
      ]);
    }
    setShowModal(false);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <div className="content-wrapper">
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2 className="dashboard-title">Ministries</h2>
          <div>
            <Button
              variant="primary"
              className="me-2"
              onClick={handleCreateClick}
            >
              Create Ministry
            </Button>
            <Button
              variant="secondary"
              onClick={() => setShowUploadModal(true)}
            >
              Upload Ministries
            </Button>
          </div>
        </div>

        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {ministries.map((ministry) => (
              <tr key={ministry.id}>
                <td>{ministry.name}</td>
                <td>{ministry.address}</td>
                <td>
                  <Button
                    variant="warning"
                    onClick={() => handleEditClick(ministry)}
                  >
                    ✏️ Edit
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Create/Edit Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedMinistry ? "Edit" : "Create"} Ministry
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Ministry Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Upload Modal */}
      <Modal
        show={showUploadModal}
        onHide={() => setShowUploadModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Ministries</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Select File</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUploadModal(false)}>
            Close
          </Button>
          <Button variant="primary" disabled={!file}>
            Upload File
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Ministries;
