import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button, Form, Card } from "react-bootstrap";
import { ArrowBack, Check, Cancel } from "@mui/icons-material";

const MinistryCreditSpend = () => {
  const [ministries, setMinistries] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    // Sample data for testing
    const sampleData = [
      { id: 1, name: "Ministry of Health", amount: 120000 },
      { id: 2, name: "Ministry of Education", amount: 250000000 },
      { id: 3, name: "Ministry of Finance", amount: 950000 },
    ];
    setMinistries(sampleData);
  }, []);

  return (
    <div className="content-wrapper">
      <div className="container-fluid">
        <div className="dashboard-head">
          <div className="buttons">
            <Button
              className="btn btn-light btn-sm"
              type="button"
              onClick={() => navigate(-1)}
            >
              <ArrowBack /> Back
            </Button>
          </div>
        </div>
        <h2 className="dashboard-title me-auto">Transactions</h2>
        <table className="table">
          <thead>
            <tr>
              <th>Ministry</th>
              <th>Transaction Value</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {ministries.map((ministry) => (
              <tr key={ministry.id}>
                <td>{ministry.name}</td>
                <td>₦{ministry.amount.toLocaleString()}</td>
                <td>
                  <Link
                    to={`/credit-spend/${ministry.id}`}
                    className="btn btn-primary"
                  >
                    👁 View
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MinistryCreditSpend;
