import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export async function getStaffLevels() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/get-staff-levels`
    );
    return response;
  } catch (error) {
    const errorData = error.response ? error.response.data : error;
    toast.error(errorData.message);
    console.error("There was an error fetching staff levels:", error);
    return;
  }
}
