import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IMAGES, SVGICON } from "../constant/theme";
import { Logout } from "../../admin/store/actions/AuthActions";
import { useDispatch } from "react-redux";

export function NavMenuToggle() {
  setTimeout(() => {
    let sidebarBlog = document.querySelector("#frontSidebar");
    let sidebarMenu = document.getElementById("SidebarMenu");
    if (sidebarBlog.classList.contains("active")) {
      sidebarBlog.classList.remove("active");
      sidebarMenu.classList.remove("open");
    } else {
      sidebarBlog.classList.add("active");
      sidebarMenu.classList.add("open");
    }
  }, 200);
}

const Header = () => {
  const [user, setUser] = useState(null);
  const [businessName, setBusinessName] = useState("Ekiti Government");
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const dispatch = useDispatch();
  const nav = useNavigate();

  useEffect(() => {
    const userData = sessionStorage.getItem("userData");
    if (userData) {
      try {
        const parsedUser = JSON.parse(userData);
        setUser(parsedUser); // ✅ Store user globally in state
        if (parsedUser?.merchant_detail?.business_name) {
          setBusinessName(parsedUser.merchant_detail.business_name);
        }
      } catch (error) {
        console.error("Failed to parse userData:", error);
      }
    }
  }, []);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(Logout(nav));
  };

  return (
    <>
      <header className="site-header mo-left header style-1">
        <div className="sticky-header main-bar-wraper navbar-expand-xl">
          <div className="main-bar clearfix">
            <div className="container-fluid clearfix">
              {/* Website Logo */}
              <div className="logo-header mostion logo-dark">
                <Link to={"/dashboard"}>
                  <img src={IMAGES.LogoText2} alt="" />
                </Link>
              </div>

              {/* Nav Toggle Button */}
              <button
                className="navbar-toggler collapsed navicon justify-content-end"
                type="button"
              >
                <span></span>
                <span></span>
                <span></span>
              </button>

              {/* Extra Nav */}
              <div className="extra-nav">
                <div className="extra-cell">
                  <div
                    className="profile-box"
                    onClick={toggleDropdown}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="header-info">
                      <span
                        style={{
                          fontWeight: "bold",
                          textTransform: "capitalize",
                        }}
                      >
                        {businessName}
                      </span>

                      <small>
                        {user?.user_type_id === 1 ||
                        user?.user_type_id === 2 ||
                        user?.user_type_id === 5
                          ? "Admin"
                          : "Vendor"}
                      </small>
                    </div>
                    <div className="img-bx">
                      <img src={IMAGES.Avatar2} alt="Profile" />
                    </div>
                  </div>
                  {dropdownVisible && (
                    <div
                      className="dropdown-menu show"
                      style={{
                        position: "absolute",
                        right: 0,
                        top: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <button
                        onClick={handleLogout}
                        className="dropdown-item text-danger"
                      >
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              </div>

              {/* Sidebar Toggle */}
              <div className={`sidebar-menu`} onClick={NavMenuToggle}>
                <div className={`menu-btn navicon`} id="SidebarMenu">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
