import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import { SVGICON } from "../constant/theme";

const tableDataBlog = [
  { title: "Alayo Ventures" },
  { title: "Fayose Food Stores" },
  { title: "Johnson Stores" },
  { title: "Alese Yam Store" },
  { title: "Atinuke Products" },
  { title: "Pamilerin Food Ventures" },
  { title: "Fayemi Food Stores" },
  { title: "Ajala Food Stores" },
];

const ApprovedVendors = () => {
  const [showModal, setShowModal] = useState(false);
  const [vendorData, setVendorData] = useState({
    fullName: "",
    businessName: "",
    email: "",
    password: "",
    address: "",
  });

  const handleInputChange = (e) => {
    setVendorData({ ...vendorData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("New Vendor Data:", vendorData);
    setShowModal(false); // Close modal after submission
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="form-head dashboard-head d-md-flex d-block mb-5 align-items-start">
            <h6 className="dashboard-title me-auto"> VENDORS </h6>
            <div className="input-group search-area">
              <input
                type="text"
                className="form-control"
                placeholder="Search here..."
              />
              <span className="input-group-text">
                <Link to={"#"}>
                  <i className="flaticon-381-search-2"></i>
                </Link>
              </span>
            </div>

            {/* Create Vendor Button */}
            <button
              className="btn btn-primary ms-3"
              onClick={() => setShowModal(true)}
            >
              Create New Vendor
            </button>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <table className="display mb-4 w-100 defaultTable dataTable">
                  <thead>
                    <tr>
                      <th>BUSINESS NAME</th>
                      <th>PHONE NUMBER</th>
                      <th>EMAIL ADDRESS</th>
                      <th>CREATED</th>
                      <th className="text-end">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableDataBlog.map((data, ind) => (
                      <tr key={ind}>
                        <td>
                          <strong>{data.title}</strong>
                        </td>
                        <td>08012345678</td>
                        <td>example@email.com</td>
                        <td>12 June 2024 12:30 pm</td>
                        <td className="text-end">
                          <Link to="#" className="btn btn-danger light me-2">
                            {SVGICON.Delete}
                          </Link>
                          <Link
                            to={`/approved-kyc/${ind}`}
                            className="btn btn-primary light"
                          >
                            {SVGICON.Eyes}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Creating Vendor */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create New Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                value={vendorData.fullName}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Business Name</Form.Label>
              <Form.Control
                type="text"
                name="businessName"
                value={vendorData.businessName}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={vendorData.email}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={vendorData.password}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={vendorData.address}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100">
              Save Vendor
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ApprovedVendors;
