import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const MinistryCivilServants = () => {
  const [ministries, setMinistries] = useState([]);

  useEffect(() => {
    // Sample data for testing
    const sampleData = [
      { id: 1, name: "Ministry of Health", count: 120 },
      { id: 2, name: "Ministry of Education", count: 200 },
      { id: 3, name: "Ministry of Finance", count: 95 },
    ];
    setMinistries(sampleData);
  }, []);

  return (
    <div className="content-wrapper">
      <div className="container-fluid">
        <h2 className="dashboard-title">Ministries</h2>
        <table className="table">
          <thead>
            <tr>
              <th>Ministry</th>
              <th>Customer counts</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {ministries.map((ministry) => (
              <tr key={ministry.id}>
                <td>{ministry.name}</td>
                <td>{ministry.count}</td>
                <td>
                  <Link
                    to={`/civil-servants/${ministry.id}`}
                    className="btn btn-primary"
                  >
                    👁 View
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MinistryCivilServants;
