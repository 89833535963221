import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { SVGICON } from "../constant/theme";
import { NavMenuToggle } from "./Header";

const Sidebar = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [user, setUser] = useState(null);
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const userData = sessionStorage.getItem("userData");
    if (userData) {
      try {
        const parsedUser = JSON.parse(userData);
        setUser(parsedUser);
        console.log("checking user type id", parsedUser?.user_type_id);

        if (parsedUser?.user_type_id == "3") {
          // Vendor Menu
          setMenuItems([
            // { title: "Orders", to: "/vendor-orders", icon: SVGICON.Order },
            {
              title: "Order",
              to: "#",
              icon: SVGICON.Order,
              subItems: [
                {
                  title: "Pending Orders",
                  to: "/vendor-pending-order",
                },
                { title: "Completed Orders", to: "/vendor-completed-order" },
              ],
            },
            {
              title: "Products",
              to: "/vendor-products",
              icon: SVGICON.Product,
            },
            // {
            //   title: "Transactions",
            //   to: "/vendor-transactions",
            //   icon: SVGICON.Wallet,
            // },
            {
              title: "Transactions",
              to: "#",
              icon: SVGICON.Wallet,
              subItems: [
                {
                  title: "Credit Spend",
                  to: "/credit-spend/2",
                },
                {
                  title: "Cash Spend",
                  to: "/staff-personal-spend",
                },
              ],
            },
            {
              title: "Settings",
              to: "/front-setting",
              icon: SVGICON.Setting,
            },
          ]);
        } else {
          // Admin Menu
          setMenuItems([
            { title: "Dashboard", to: "/dashboard", icon: SVGICON.Dashboard },
            ...(parsedUser.user_type_id == "5"
              ? []
              : [
                  {
                    title: "Vendors",
                    to: "/approved-vendors",
                    icon: SVGICON.User,
                  },
                ]),
            ...(parsedUser.user_type_id == "5"
              ? []
              : [
                  {
                    title: "Approved Products",
                    to: "/approved-merchant-products",
                    icon: SVGICON.Product,
                  },
                ]),
            // { title: "Vendors", to: "/approved-vendors", icon: SVGICON.User },
            {
              title: "Staffs",
              to: "#",
              icon: SVGICON.People,
              subItems: [
                {
                  title: "Civil Servants",
                  to:
                    parsedUser.user_type_id == "5"
                      ? "/civil-servants/" + parsedUser.id
                      : "/ministry-civil-servants",
                },
                ...(parsedUser.user_type_id == "5"
                  ? []
                  : [
                      {
                        title: "Non Civil Servants",
                        to: "/non-civil-servants",
                      },
                    ]),
              ],
            },
            ...(parsedUser.user_type_id == "5"
              ? [
                  {
                    title: "Staff Levels",
                    to: "/staff-levels/" + parsedUser.id,
                    icon: SVGICON.User,
                  },
                ]
              : []),
            {
              title: "Transactions",
              to: "#",
              icon: SVGICON.Wallet,
              subItems: [
                {
                  title: "Credit Spend",
                  to:
                    parsedUser.user_type_id == "5"
                      ? "/credit-spend/" + parsedUser.id
                      : "/ministry-credit-spend",
                },
                {
                  title: "Cash Spend",
                  to:
                    parsedUser.user_type_id == "5"
                      ? "/staff-personal-spend"
                      : "/personal-spend",
                },
              ],
            },
            parsedUser.user_type_id == "2"
              ? {
                  title: "Ministry Management",
                  to: "#",
                  icon: SVGICON.People,
                  subItems: [
                    {
                      title: "Ministry List",
                      to: "/ministries",
                    },
                    {
                      title: "Ministry Access",
                      to: "/ministry-management",
                    },
                  ],
                }
              : [],
          ]);
        }
      } catch (error) {
        console.error("Failed to parse userData:", error);
      }
    }
  }, []);

  const toggleDropdown = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <>
      <div className="menu-sidebar" id="frontSidebar">
        <div className="contact-box">
          <ul>
            {menuItems.map((data, index) => (
              <li className="nav-item mb-2" key={index}>
                {data.subItems ? (
                  <div
                    onClick={() => toggleDropdown(index)}
                    className="nav-link"
                    style={{ marginLeft: "13px" }}
                  >
                    {data.icon} {data.title}
                  </div>
                ) : (
                  <Link to={data.to} className="nav-link">
                    {data.icon} {data.title}
                  </Link>
                )}
                {data.subItems && activeIndex === index && (
                  <ul className="submenu">
                    {data.subItems.map((subItem, subIndex) => (
                      <li className="nav-item" key={subIndex}>
                        <Link to={subItem.to} className="nav-link">
                          {subItem.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="menu-close" onClick={() => NavMenuToggle()}></div>
    </>
  );
};

export default Sidebar;
