import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";

const StaffLevels = () => {
  const [levels, setLevels] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [formData, setFormData] = useState({ name: "", base_salary: "" });
  const [file, setFile] = useState(null);

  useEffect(() => {
    // Sample data for testing
    const sampleData = [
      { id: 1, name: "AG_LEVEL_1", base_salary: 50000 },
      { id: 2, name: "AG_LEVEL_2", base_salary: 70000 },
      { id: 3, name: "AG_LEVEL_13", base_salary: 90000 },
    ];
    setLevels(sampleData);
  }, []);

  // Open modal & populate form with selected level's data
  const handleEditClick = (level) => {
    setSelectedLevel(level);
    setFormData({ name: level.name, base_salary: level.base_salary });
    setShowModal(true);
  };

  const handleCreateClick = () => {
    setSelectedLevel(null);
    setFormData({ name: "", base_salary: "" });
    setShowModal(true);
  };

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Save updates & close modal
  const handleSave = () => {
    if (selectedLevel) {
      setLevels(
        levels.map((level) =>
          level.id === selectedLevel.id
            ? {
                ...level,
                name: formData.name,
                base_salary: formData.base_salary,
              }
            : level
        )
      );
    } else {
      setLevels([...levels, { id: levels.length + 1, ...formData }]);
    }
    setShowModal(false);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <div className="content-wrapper">
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2 className="dashboard-title">Staff Levels</h2>
          <div>
            <Button
              variant="primary"
              className="me-2"
              onClick={handleCreateClick}
            >
              Create Staff Level
            </Button>
            <Button
              variant="secondary"
              onClick={() => setShowUploadModal(true)}
            >
              Upload Staff Levels
            </Button>
          </div>
        </div>

        <table className="table">
          <thead>
            <tr>
              <th>Level</th>
              <th>Assigned Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {levels.map((level) => (
              <tr key={level.id}>
                <td>{level.name}</td>
                <td>{level.base_salary}</td>
                <td>
                  <Button
                    variant="warning"
                    onClick={() => handleEditClick(level)}
                  >
                    ✏️ Edit
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Create/Edit Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedLevel ? "Edit" : "Create"} Staff Level
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Level Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Base Salary</Form.Label>
              <Form.Control
                type="number"
                name="base_salary"
                value={formData.base_salary}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Upload Modal */}
      <Modal
        show={showUploadModal}
        onHide={() => setShowUploadModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Staff Levels</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Select File</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUploadModal(false)}>
            Close
          </Button>
          <Button variant="primary" disabled={!file}>
            Upload File
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StaffLevels;
