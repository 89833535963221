import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import { SVGICON } from "../constant/theme";

const ministries = [
  "Ministry of Education",
  "Ministry of Health",
  "Ministry of Finance",
];

const tableDataBlog = [
  { title: "Cindy Stark" },
  { title: "David Lee" },
  { title: "Emilia Johnson" },
  { title: "Franklin Mc. Good" },
  { title: "James Rodriguez" },
  { title: "Mark Steven" },
  { title: "Tommy Hank" },
  { title: "Wanda Hummels" },
];

const FrontPeople = () => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedMinistry, setSelectedMinistry] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const [data, setData] = useState(
    document.querySelectorAll("#example7_wrapper tbody tr")
  );
  const sort = 8;
  const activePag = useRef(0);
  const [test, setTest] = useState(0);

  // Handle ministry selection
  const handleMinistryChange = (e) => {
    setSelectedMinistry(e.target.value);
  };

  // Handle file upload
  const handleFileChange = (e) => {
    setUploadedFile(e.target.files[0]);
  };

  // Handle file upload submission
  const handleUploadSubmit = (e) => {
    e.preventDefault();
    console.log("Uploading:", uploadedFile, "for Ministry:", selectedMinistry);
    setShowUploadModal(false);
  };

  // Handle search filtering
  const filteredData = tableDataBlog.filter((data) =>
    data.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Active data
  const changeData = (first, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= first && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  useEffect(() => {
    setData(document.querySelectorAll("#example7_wrapper tbody tr"));
  }, [test]);

  activePag.current === 0 && changeData(0, sort);
  let pagination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const onClick = (i) => {
    activePag.current = i;
    changeData(activePag.current * sort, (activePag.current + 1) * sort);
    setTest(i);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="form-head dashboard-head d-md-flex d-block mb-5 align-items-start">
            <h2 className="dashboard-title me-auto">
              Customers
              <Link
                to={"#"}
                className="btn btn-success btn-rounded ms-4 text-white d-inline-block"
              >
                Add New
              </Link>
              <Button
                className="btn btn-primary btn-rounded ms-3"
                onClick={() => setShowUploadModal(true)}
              >
                Upload Customers
              </Button>
            </h2>
            <div className="input-group search-area">
              <input
                type="text"
                className="form-control"
                placeholder="Search here..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <span className="input-group-text">
                <Link to={"#"}>
                  <i className="flaticon-381-search-2"></i>
                </Link>
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <div
                  id="example7_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example5"
                    className="display mb-4 w-100 defaultTable dataTablesCard dataTable no-footer"
                    style={{ minWidth: "845px" }}
                  >
                    <thead>
                      <tr>
                        <th>NAME</th>
                        <th>PHONE NUMBER</th>
                        <th>EMAIL ADDRESS</th>
                        <th>CREATED</th>
                        <th className="text-end">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.map((data, ind) => (
                        <tr key={ind}>
                          <td>
                            <strong>{data.title}</strong>
                          </td>
                          <td>+1 987 675 5432</td>
                          <td>example@email.com</td>
                          <td>12 June 2020 12:30 pm</td>
                          <td>
                            <div className="action-buttons d-flex justify-content-end">
                              <Link
                                to={"#"}
                                className="btn btn-danger light me-2"
                              >
                                {SVGICON.Delete}
                              </Link>
                              <Link to={"#"} className="btn btn-primary light">
                                {SVGICON.Edit}
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                    <div className="dataTables_info">
                      Showing {activePag.current * sort + 1} to{" "}
                      {data.length > (activePag.current + 1) * sort
                        ? (activePag.current + 1) * sort
                        : data.length}{" "}
                      of {data.length} entries
                    </div>
                    <div className="dataTables_paginate paging_simple_numbers mb-0">
                      <Link
                        className="paginate_button previous"
                        to="/front-people"
                        onClick={() =>
                          activePag.current > 0 &&
                          onClick(activePag.current - 1)
                        }
                      >
                        Previous
                      </Link>
                      <span>
                        {pagination.map((number, i) => (
                          <Link
                            key={i}
                            to="/front-people"
                            className={`paginate_button ${
                              activePag.current === i ? "current" : ""
                            }`}
                            onClick={() => onClick(i)}
                          >
                            {number}
                          </Link>
                        ))}
                      </span>
                      <Link
                        className="paginate_button next"
                        to="/front-people"
                        onClick={() =>
                          activePag.current + 1 < pagination.length &&
                          onClick(activePag.current + 1)
                        }
                      >
                        Next
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Upload Customers Modal */}
      <Modal
        show={showUploadModal}
        onHide={() => setShowUploadModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Customers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleUploadSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Select Ministry</Form.Label>
              <Form.Select
                value={selectedMinistry}
                onChange={handleMinistryChange}
                required
              >
                <option value="">Choose Ministry</option>
                {ministries.map((ministry, index) => (
                  <option key={index} value={ministry}>
                    {ministry}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Upload File</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} required />
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100">
              Upload
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FrontPeople;
