import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Search, ArrowBack } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

// Dummy data for completed orders
const tableDataOrders = [
  {
    orderId: "ORD12345",
    productName: "50kg Rice",
    amount: "₦1500.00",
    date: "02-02-2025",
    status: "Completed",
    customerName: "John Doe",
  },
  {
    orderId: "ORD12346",
    productName: "Kings Groundnut Oil",
    amount: "₦2500.00",
    date: "10-02-2025",
    status: "Completed",
    customerName: "Jane Smith",
  },
  {
    orderId: "ORD12347",
    productName: "Oloyin Beans (per derica)",
    amount: "₦1200.00",
    date: "12-02-2025",
    status: "Completed",
    customerName: "Michael Brown",
  },
  {
    orderId: "ORD12348",
    productName: "Honeywell Semolina (1kg)",
    amount: "₦1800.00",
    date: "15-02-2025",
    status: "Completed",
    customerName: "Sarah Johnson",
  },
];

const VendorCompletedOrders = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [filteredOrders, setFilteredOrders] = useState(tableDataOrders);

  // Handle search functionality
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
    const filtered = tableDataOrders.filter(
      (order) =>
        order.orderId.includes(value) ||
        order.productName.toLowerCase().includes(value.toLowerCase()) ||
        order.customerName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOrders(filtered);
  };

  // Handle export functionality
  const handleExport = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      filteredOrders.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "completed_orders.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Handle generate receipt functionality
  const handleGenerateReceipt = (orderId) => {
    alert(`Generating receipt for Order ID: ${orderId}`);
    // Add logic to generate and download the receipt
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="form-head dashboard-head d-md-flex d-block mb-4 align-items-start">
            <Button
              className="btn btn-light btn-sm me-2"
              onClick={() => navigate(-1)}
            >
              <ArrowBack /> Back
            </Button>
            <h2 className="dashboard-title me-auto">Completed Orders</h2>
            <div className="d-flex gap-2">
              <Form.Control
                type="text"
                placeholder="Search by Order ID, Product Name, or Customer Name"
                value={searchText}
                onChange={handleSearch}
                style={{ width: "300px" }}
              />
              <Button
                className="btn btn-secondary btn-sm"
                onClick={handleExport}
              >
                Export to CSV
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>ORDER ID</th>
                      <th>PRODUCT NAME</th>
                      <th>AMOUNT</th>
                      <th>DATE</th>
                      <th>STATUS</th>
                      <th>CUSTOMER NAME</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredOrders.map((order, index) => (
                      <tr key={index}>
                        <td>
                          <strong>{order.orderId}</strong>
                        </td>
                        <td>{order.productName}</td>
                        <td>{order.amount}</td>
                        <td>{order.date}</td>
                        <td>
                          <p className="text-success">{order.status}</p>
                        </td>
                        <td>{order.customerName}</td>
                        <td>
                          <Button
                            className="btn btn-primary btn-sm"
                            onClick={() => handleGenerateReceipt(order.orderId)}
                          >
                            Generate Receipt
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorCompletedOrders;
