import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";

const ministriesData = [
  {
    id: 1,
    contactPerson: "Idowu Toyosi",
    ministryName: "Health Ministry",
    address: "ado ekiti, Ekiti",
    email: "health@ekitigov.ng",
    status: "Active",
  },
  {
    id: 2,
    contactPerson: "Fashae Temilade",
    ministryName: "Finance Ministry",
    address: "Ilawe-ekiti, Ekiti",
    email: "finance@ekitigov.ng",
    status: "Inactive",
  },
];

const availableMinistries = [
  "Health Ministry",
  "Finance Ministry",
  "Education Ministry",
  "Agriculture Ministry",
];

const MinistryManagement = () => {
  const [showModal, setShowModal] = useState(false);
  const [ministryData, setMinistryData] = useState({
    contactPerson: "",
    ministryName: "",
    address: "",
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    setMinistryData({ ...ministryData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("New Ministry Data:", ministryData);
    setShowModal(false);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="form-head dashboard-head d-md-flex d-block mb-5 align-items-start">
            <h6 className="dashboard-title me-auto">MINISTRIES</h6>
            <div className="input-group search-area">
              <input
                type="text"
                className="form-control"
                placeholder="Search here..."
              />
              <span className="input-group-text">
                <Link to={"#"}>
                  <i className="flaticon-381-search-2"></i>
                </Link>
              </span>
            </div>
            <button
              className="btn btn-primary ms-3"
              onClick={() => setShowModal(true)}
            >
              Create New Ministry
            </button>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <table className="display mb-4 w-100 defaultTable dataTable">
                  <thead>
                    <tr>
                      <th>CONTACT PERSON</th>
                      <th>MINISTRY NAME</th>
                      <th>ADDRESS</th>
                      <th>EMAIL</th>
                      <th>STATUS</th>
                      <th className="text-end">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ministriesData.map((data, ind) => (
                      <tr key={ind}>
                        <td>
                          <strong>{data.contactPerson}</strong>
                        </td>
                        <td>{data.ministryName}</td>
                        <td>{data.address}</td>
                        <td>{data.email}</td>
                        <td>{data.status}</td>
                        <td className="text-end">
                          <Link to="#" className="btn btn-danger light me-2">
                            Delete
                          </Link>
                          <Link
                            to={`/ministry-details/${data.id}`}
                            className="btn btn-primary light"
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create New Ministry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Contact Person</Form.Label>
              <Form.Control
                type="text"
                name="contactPerson"
                value={ministryData.contactPerson}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Ministry Name</Form.Label>
              <Form.Select
                name="ministryName"
                value={ministryData.ministryName}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a Ministry</option>
                {availableMinistries.map((ministry, index) => (
                  <option key={index} value={ministry}>
                    {ministry}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={ministryData.email}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={ministryData.password}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={ministryData.address}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100">
              Save Ministry
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MinistryManagement;
