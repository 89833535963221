import React, { useState } from "react";
import { Button, Form, Modal, Offcanvas } from "react-bootstrap";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const tableDataPendingOrders = [
  {
    orderId: "ORD12345",
    productName: "50kg Rice",
    amount: "₦1500.00",
    date: "02-02-2025",
    status: "Pending",
    customerName: "John Toyosi",
    items: [{ name: "50kg Rice", quantity: 1, price: "₦1500.00" }],
  },
  {
    orderId: "ORD12346",
    productName: "Kings Groundnut Oil",
    amount: "₦2500.00",
    date: "10-02-2025",
    status: "Pending",
    customerName: "David Olawale",
    items: [{ name: "Kings Groundnut Oil", quantity: 1, price: "₦2500.00" }],
  },
];

const VendorPendingOrders = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [filteredOrders, setFilteredOrders] = useState(tableDataPendingOrders);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
    const filtered = tableDataPendingOrders.filter(
      (order) =>
        order.orderId.includes(value) ||
        order.productName.toLowerCase().includes(value.toLowerCase()) ||
        order.customerName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOrders(filtered);
  };

  const handleCodeSubmit = () => {
    const foundOrder = tableDataPendingOrders.find(
      (order) => order.orderId === confirmationCode
    );

    if (foundOrder) {
      setSelectedOrder(foundOrder);
      setShowModal(true);
    } else {
      alert("Invalid order confirmation code!");
    }
  };

  const handleViewOrder = (order) => {
    setSelectedOrder(order);
    setShowOrderDetails(true);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="form-head dashboard-head d-md-flex d-block mb-4 align-items-start">
            <Button
              className="btn btn-light btn-sm me-2"
              onClick={() => navigate(-1)}
            >
              <ArrowBack /> Back
            </Button>
            <h2 className="dashboard-title me-auto">Pending Orders</h2>

            {/* Confirmation Code Input */}
            <div className="d-flex gap-2 align-items-center">
              <Form.Control
                type="text"
                placeholder="Enter Confirmation Code"
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                style={{ width: "250px" }}
              />
              <Button variant="primary" onClick={handleCodeSubmit}>
                Confirm Order
              </Button>
            </div>
          </div>

          {/* Search Bar */}
          <div className="mb-3">
            <Form.Control
              type="text"
              placeholder="Search orders..."
              value={searchText}
              onChange={handleSearch}
              style={{ width: "300px" }}
            />
          </div>

          {/* Orders Table */}
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>ORDER ID</th>
                      <th>CUSTOMER NAME</th>
                      <th>TOTAL AMOUNT</th>
                      <th>DATE</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredOrders.map((order, index) => (
                      <tr key={index}>
                        <td>
                          <strong>{order.orderId}</strong>
                        </td>
                        <td>{order.customerName}</td>
                        <td>{order.amount}</td>
                        <td>{order.date}</td>
                        <td>
                          <Button
                            variant="info"
                            onClick={() => handleViewOrder(order)}
                          >
                            View
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Order Details Sidebar */}
      <Offcanvas
        show={showOrderDetails}
        onHide={() => setShowOrderDetails(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Order Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {selectedOrder && (
            <>
              <p>
                <strong>Order ID:</strong> {selectedOrder.orderId}
              </p>
              <p>
                <strong>Customer:</strong> {selectedOrder.customerName}
              </p>
              <p>
                <strong>Total Amount:</strong> {selectedOrder.amount}
              </p>
              <p>
                <strong>Date:</strong> {selectedOrder.date}
              </p>

              <h5>Items Ordered:</h5>
              <ul>
                {selectedOrder.items.map((item, i) => (
                  <li key={i}>
                    {item.name} - {item.quantity} x {item.price}
                  </li>
                ))}
              </ul>

              <Button
                variant="success"
                className="mt-2"
                onClick={() => alert("Order Picked Up!")}
              >
                Mark as Delivered
              </Button>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>

      {/* Order Details Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Order Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedOrder && (
            <>
              <p>
                <strong>Order ID:</strong> {selectedOrder.orderId}
              </p>
              <p>
                <strong>Customer:</strong> {selectedOrder.customerName}
              </p>
              <p>
                <strong>Total Amount:</strong> {selectedOrder.amount}
              </p>
              <p>
                <strong>Date:</strong> {selectedOrder.date}
              </p>

              <h5>Items Ordered:</h5>
              <ul>
                {selectedOrder.items.map((item, i) => (
                  <li key={i}>
                    {item.name} - {item.quantity} x {item.price}
                  </li>
                ))}
              </ul>

              <Button
                variant="success"
                className="mt-2"
                onClick={() => alert("Order Picked Up!")}
              >
                Mark as Delivered
              </Button>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VendorPendingOrders;
