import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button, Form, Card } from "react-bootstrap";
import { ArrowBack, Check, Cancel } from "@mui/icons-material";
import { FaMoneyBillWave, FaShoppingCart } from "react-icons/fa";
import { IMAGES, SVGICON } from "../constant/theme";
import { date } from "yup";

const tableDataBlog = [
  {
    title: "Wiliam Franchi",
    payment_method: "Card",
    date: "25 January 2025 11:30 am",
  },
  {
    title: "Samoch Raw",
    payment_method: "Bank Transfer",
    date: "12 February 2025 10:17 am",
  },
  {
    title: "Wiliam John",
    payment_method: "Card",
    date: "13 February 2025 08:30 am",
  },
  {
    title: "Wiliam Franchi",
    payment_method: "Bank Transfer",
    date: "15 February 2025 11:00 am",
  },
  {
    title: "Samoch Raw",
    payment_method: "Bank Transfer",
    date: "16 February 2025 09:00 am",
  },
  {
    title: "Wiliam John",
    payment_method: "Bank Transfer",
    date: "20 February 2025 03:07 pm",
  },
  {
    title: "Wiliam Franchi",
    payment_method: "Card",
    date: "24 February 2025 11:11 am",
  },
  {
    title: "Samoch Raw",
    payment_method: "Card",
    date: "24 February 2025 11:46 am",
  },
  {
    title: "Wiliam John",
    payment_method: "Bank Transfer",
    date: "25 February 2025 3:23 pm",
  },
  {
    title: "Wiliam Franchi",
    payment_method: "Bank Transfer",
    date: "25 February 2025 5:15 pm",
  },
  {
    title: "Samoch Raw",
    payment_method: "Card",
    date: "25 February 2025 5:33 pm",
  },
];

const ministrySummary = {
  totalSpend: 1500000,
  orderCount: 120,
};

const StaffPersonalSpend = () => {
  const [detailModal, setDetailModal] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [summaryData, setSummaryData] = useState({
    totalSpent: 100000,
    orderCount: 58,
  });
  function OpenModal() {
    setDetailModal(true);
  }

  const navigate = useNavigate();

  const [data, setData] = useState(
    document.querySelectorAll("#example6_wrapper tbody tr")
  );
  const sort = 10;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#example6_wrapper tbody tr"));
    //chackboxFun();
  }, [test]);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="dashboard-head">
            <div className="buttons">
              <Button
                className="btn btn-light btn-sm"
                type="button"
                onClick={() => navigate(-1)}
              >
                <ArrowBack /> Back
              </Button>
            </div>
          </div>
          <div className="form-head dashboard-head d-md-flex d-block mb-5 align-items-start">
            <h2 className="dashboard-title me-auto">Transactions</h2>
            <div className="input-group search-area">
              <input
                type="text"
                className="form-control"
                placeholder="Search here..."
              />
              <span className="input-group-text">
                <Link to={"#"}>
                  <i className="flaticon-381-search-2"></i>
                </Link>
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {/* Summary Section */}

              <div className="summary-section mb-4 p-3 bg-white rounded shadow-sm d-flex align-items-center gap-4 flex-wrap">
                {/* Month Selector */}
                <div className="d-flex align-items-center gap-3">
                  <label className="fw-bold">Select Month:</label>
                  <Form.Select
                    className="form-select"
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(Number(e.target.value))}
                    style={{ minWidth: "150px" }}
                  >
                    {Array.from({ length: 12 }, (_, i) => (
                      <option key={i + 1} value={i + 1}>
                        {new Date(2024, i).toLocaleString("default", {
                          month: "long",
                        })}
                      </option>
                    ))}
                  </Form.Select>
                </div>

                {/* Summary Cards */}
                <div className="d-flex flex-grow-1 justify-content-end gap-3">
                  {/* Total Spent */}
                  <Card className="summary-card border-0 shadow-sm p-3 d-flex flex-row align-items-center">
                    <FaMoneyBillWave className="text-success fs-3 me-2" />
                    <div>
                      <h6 className="text-muted mb-1">Total Spent</h6>
                      <h4 className="fw-bold text-dark">
                        ₦{summaryData.totalSpent.toLocaleString()}
                      </h4>
                    </div>
                  </Card>

                  {/* Order Count */}
                  <Card className="summary-card border-0 shadow-sm p-3 d-flex flex-row align-items-center">
                    <FaShoppingCart className="text-primary fs-3 me-2" />
                    <div>
                      <h6 className="text-muted mb-1">Order Count</h6>
                      <h4 className="fw-bold text-dark">
                        {summaryData.orderCount}
                      </h4>
                    </div>
                  </Card>
                </div>
              </div>

              <div className="table-responsive">
                <div
                  id="example6_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example5"
                    className="display mb-4 w-100 defaultTable dataTablesCard dataTable no-footer"
                    style={{ minWidth: "845px" }}
                  >
                    <thead>
                      <tr>
                        <th>NAME</th>
                        <th>AMOUNT</th>
                        <th>PAYMENT METHOD</th>
                        <th>UPDATED ON</th>
                        <th className="text-end">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableDataBlog.map((data, ind) => (
                        <tr key={ind}>
                          <td>
                            <strong>{data.title}</strong>
                          </td>
                          <td>₦6700.00</td>
                          <td> {data.payment_method} </td>
                          <td> {data.date} </td>
                          <td>
                            <div className="action-buttons d-flex justify-content-end">
                              <Link
                                to={"#"}
                                className="btn btn-success light me-2"
                                data-bs-toggle="modal"
                                onClick={() => OpenModal()}
                              >
                                {SVGICON.Eyes}
                              </Link>
                              {/* <Link
                                to={"#"}
                                className="btn btn-primary light me-2"
                              >
                                {SVGICON.Edit}
                              </Link>
                              <Link to={"#"} className="btn btn-danger light">
                                {SVGICON.Delete}
                              </Link> */}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                    <div className="dataTables_info">
                      Showing {activePag.current * sort + 1} to{" "}
                      {data.length > (activePag.current + 1) * sort
                        ? (activePag.current + 1) * sort
                        : data.length}{" "}
                      of {data.length} entries
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers mb-0"
                      id="application-tbl1_paginate"
                    >
                      <Link
                        className="paginate_button previous "
                        to="/front-transactions"
                        onClick={() =>
                          activePag.current > 0 &&
                          onClick(activePag.current - 1)
                        }
                      >
                        Previous
                        {/* <i className="fa fa-angle-double-left" ></i>  */}
                      </Link>
                      <span>
                        {paggination.map((number, i) => (
                          <Link
                            key={i}
                            to="/front-transactions"
                            className={`paginate_button  ${
                              activePag.current === i ? "current" : ""
                            } `}
                            onClick={() => onClick(i)}
                          >
                            {number}
                          </Link>
                        ))}
                      </span>

                      <Link
                        className="paginate_button next"
                        to="/front-transactions"
                        onClick={() =>
                          activePag.current + 1 < paggination.length &&
                          onClick(activePag.current + 1)
                        }
                      >
                        {/* <i className="fa fa-angle-double-right" ></i> */}
                        Next
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Modal  */}
          <Modal
            className="modal order-info-box"
            id="OrderInfoBox"
            show={detailModal}
            onHide={setDetailModal}
          >
            <div className="modal-header">
              <div>
                <h5 className="user-name">Wiliam Franchi</h5>
                <span className="date"> February 2025 </span>
              </div>
            </div>
            <div className="modal-body">
              <ul className="order-list-wrapper">
                <li className="list-header">
                  <span className="sn">Q.</span>DATE<span>AMOUNT SPENT</span>
                </li>
                <li>
                  <span className="sn">1.</span>12th Feb,2025
                  <span>₦1200.00</span>
                </li>
                <li>
                  <span className="sn">1.</span>15th Feb,2025
                  <span>₦2000.00</span>
                </li>
                <li>
                  <span className="sn">1.</span>22nd Feb,2025
                  <span>₦3500.00</span>
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <div className="w-100">
                <ul className="order-list-wrapper w-100">
                  <li className="list-footer">
                    Total<span>₦6700.00</span>
                  </li>
                </ul>
              </div>
            </div>
          </Modal>
          {/* Modal  end */}
        </div>
      </div>
    </>
  );
};

export default StaffPersonalSpend;
