import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import logo from "../../images/ekiti_small.png";
import { getMinistries } from "../../api/ministry";
import { getStaffLevels } from "../../api/staffLevel";
import { encryptPassword } from "../../../utils/encryption";
import { ToastContainer } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
  loadingToggleAction,
  signupAction,
} from "../../store/actions/AuthActions";

function Register(props) {
  const [full_name, setFullName] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [ministries, setMinistries] = useState([]);
  const [levels, setLevels] = useState([]);
  const [ministry_id, setMinistryId] = useState(null);
  const [level_id, setLevelId] = useState(null);
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  const dispatch = useDispatch();
  const nav = useNavigate();

  const handlePasswordConfirmationChange = (e) => {
    setPasswordConfirmation(e.target.value);
    validatePasswordMatch(password, e.target.value);
  };

  const validatePasswordMatch = (pass, confirmPass) => {
    if (confirmPass && pass !== confirmPass) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        passwordConfirmation: "Passwords do not match",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        passwordConfirmation: "",
      }));
    }
  };

  useEffect(() => {
    const fetchMinistries = async () => {
      const response = await getMinistries();
      const data = response.data.data;
      if (data) {
        setMinistries(data);
      }
    };

    const fetchStaffLevels = async () => {
      const response = await getStaffLevels();
      const data = response.data.data;
      console.log("levels", data);

      if (data) {
        setLevels(data);
      }
    };

    fetchMinistries();
    fetchStaffLevels();
  }, []);

  function validateEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  function onSignUp(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    } else if (!validateEmail(email)) {
      errorObj.email = "Invalid Email Format";
      error = true;
    }

    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }

    if (full_name === "") {
      errorObj.full_name = "Name is Required";
      error = true;
    }

    if (phone_number === "") {
      errorObj.phone_number = "Phone Number is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) return;
    const encryptedPassword = encryptPassword(password);

    const encryptPasswordConfirmation = encryptPassword(passwordConfirmation);

    const data = {
      full_name: full_name,
      business_name: full_name,
      email: email,
      phone_number: phone_number,
      encryptedPassword: encryptedPassword,
      encryptedPasswordConfirmation: encryptPasswordConfirmation,
      ministry_id: ministry_id || null,
      staff_level_id: level_id || null,
      user_type_id: 2,
    };
    dispatch(loadingToggleAction(true));
    dispatch(signupAction(data, nav));
  }
  return (
    <div className="authincation h-100 p-meddle">
      <ToastContainer />
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={logo}
                        alt="Logo"
                        className="logo-icon"
                        style={{
                          width: "80px",
                          height: "80px",
                          marginBottom: "15px",
                        }}
                      />
                    </div>
                    <h4 className="text-center mb-4 ">Sign up your account</h4>
                    {props.errorMessage && (
                      <div className="bg-red-300 text-danger border border-red-900 p-1 my-2">
                        {props.errorMessage}
                      </div>
                    )}
                    {props.successMessage && (
                      <div
                        className="p-1 m-2 text-center"
                        style={{ color: "#4CAF50", fontSize: "16px" }}
                      >
                        {props.successMessage}
                      </div>
                    )}
                    <form onSubmit={onSignUp}>
                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>Name</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="John Doe"
                          onChange={(e) => setFullName(e.target.value)}
                        />
                        {errors.full_name && (
                          <div className="text-danger fs-12">
                            {errors.full_name}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>Email</strong>
                        </label>
                        <input
                          type="email"
                          className="form-control form-control-sm"
                          placeholder="demo@example.com"
                          onChange={(e) => {
                            setEmail(e.target.value);
                            if (!validateEmail(e.target.value)) {
                              setErrors((prev) => ({
                                ...prev,
                                email: "Invalid Email Format",
                              }));
                            } else {
                              setErrors((prev) => ({ ...prev, email: "" }));
                            }
                          }}
                        />
                        {errors.email && (
                          <div className="text-danger fs-12">
                            {errors.email}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>Phone Number</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="08012341234"
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                        {errors.phone_number && (
                          <div className="text-danger fs-12">
                            {errors.phone_number}
                          </div>
                        )}
                      </div>
                      {/* <div className="form-group">
                        <label className="mb-1 ">
                          <strong>Ministry</strong>
                        </label>
                        <select
                          className="form-control form-control-sm"
                          id="ministry"
                          onChange={(e) => setMinistryId(e.target.value)}
                        >
                          {ministries.map((ministry) => (
                            <option key={ministry.id} value={ministry.id}>
                              {ministry.ministry}
                            </option>
                          ))}
                        </select>
                      </div> */}
                      {/* <div className='form-group'>
                                            <label className='mb-1 '>
                                                <strong>Levels</strong>
                                            </label>
                                            <select 
                                                className="form-control" 
                                                id="ministry"
                                                onChange={(e) =>
                                                    setLevelId(e.target.value)
                                                }
                                            >
                                                {levels.map((level) => (
                                                    <option key={level.id} value={level.id}>
                                                        {level.level}
                                                    </option>
                                                ))}
                                            </select>
                                        </div> */}

                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Password</strong>
                        </label>
                        <div className="position-relative">
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control form-control-sm"
                            placeholder="****"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <span
                            className="position-absolute"
                            style={{
                              right: "15px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                        {errors.password && (
                          <div className="text-danger fs-12">
                            {errors.password}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Confirm Password</strong>
                        </label>
                        <div className="position-relative">
                          <input
                            type={
                              showPasswordConfirmation ? "text" : "password"
                            }
                            className="form-control form-control-sm"
                            placeholder="****"
                            value={passwordConfirmation}
                            // onChange={(e) =>
                            //   setPasswordConfirmation(e.target.value)
                            // }
                            onChange={handlePasswordConfirmationChange}
                          />
                          <span
                            className="position-absolute"
                            style={{
                              right: "15px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setShowPasswordConfirmation(
                                !showPasswordConfirmation
                              )
                            }
                          >
                            {showPasswordConfirmation ? (
                              <FaEyeSlash />
                            ) : (
                              <FaEye />
                            )}
                          </span>
                        </div>
                        {errors.passwordConfirmation && (
                          <div className="text-danger fs-12">
                            {errors.passwordConfirmation}
                          </div>
                        )}
                      </div>

                      <div className="text-center mt-4">
                        <input
                          type="submit"
                          className="btn btn-primary btn-block"
                          style={{
                            backgroundColor: "#E85E65",
                            color: "white",
                            border: "none",
                          }}
                        />
                      </div>
                    </form>
                    <div className="new-account mt-3 ">
                      <p>
                        Already have an account?{" "}
                        <Link className="text-danger" to="/login">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Register);
