import React from "react";
import { Link } from "react-router-dom";
import { Tab, Nav, Dropdown } from "react-bootstrap";
import { IMAGES } from "../../constant/theme";

import DounutChart from "../../elements/DounutChart";
import EarningBar from "../../elements/EarningBar";
import CountUp from "react-countup";

// Vendor-specific analytics
const widgetBlog = [
  { title: "Total Products", number: "400", image: IMAGES.FoodIcon2 },
  { title: "Items Sold", number: "678", image: IMAGES.FoodIcon1 },
  { title: "Total Orders", number: "130", image: IMAGES.Order },
  { title: "Pending Orders", number: "15", image: IMAGES.PendingOrder },
];

// Order Progress Analytics
const progressBlog = [
  {
    image: IMAGES.FoodIcon8,
    title: "Dine-in",
    percent1: "602",
    percent2: "92%",
  },
  {
    image: IMAGES.FoodIcon9,
    title: "Takeaway",
    percent1: "52",
    percent2: "16%",
  },
  {
    image: IMAGES.FoodIcon10,
    title: "Online",
    percent1: "3.1k",
    percent2: "5%",
  },
];

// Latest Orders
const latestOrders = [
  {
    orderID: "#00123",
    customer: "David Lee",
    total: "₦5,000",
    status: "Completed",
  },
  {
    orderID: "#00124",
    customer: "Emilia Johnson",
    total: "₦3,500",
    status: "Pending",
  },
  {
    orderID: "#00125",
    customer: "Franklin Mc. Good",
    total: "₦12,000",
    status: "Processing",
  },
  {
    orderID: "#00126",
    customer: "James Rodriguez",
    total: "₦2,800",
    status: "Cancelled",
  },
];

const VendorDashboard = () => {
  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row">
            {/* Earnings Chart */}
            <div className="col-xl-6">
              <div id="user-activity" className="card">
                <Tab.Container defaultActiveKey="Monthly">
                  <div className="card-header border-0 pb-0 d-sm-flex d-block">
                    <h2 className="main-title mb-1">Earnings</h2>
                    <div className="card-action card-tabs mt-3 mt-sm-0">
                      <Nav as="ul" className="nav nav-tabs" role="tablist">
                        <Nav.Item as="li">
                          <Nav.Link eventKey="Monthly">Monthly</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link eventKey="Weekly">Weekly</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link eventKey="Today">Today</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </div>
                  <div className="card-body">
                    <Tab.Content id="myTabContent">
                      <Tab.Pane eventKey="Monthly">
                        <EarningBar dataActive={0} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="Weekly">
                        <EarningBar dataActive={1} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="Today">
                        <EarningBar dataActive={2} />
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Tab.Container>
              </div>
            </div>

            {/* Statistics Cards */}
            <div className="col-xl-6">
              <div className="row">
                {widgetBlog.map((item, ind) => (
                  <div className="col-sm-6" key={ind}>
                    <div className="widget-card-1 card">
                      <div className="card-body">
                        <div className="media">
                          <img
                            src={item.image}
                            alt=""
                            className="me-4"
                            width="80"
                          />
                          <div className="media-body">
                            <h3 className="mb-sm-3 mb-2 text-black">
                              <CountUp
                                className="counter ms-0"
                                end={item.number}
                                duration={5}
                              />
                            </h3>
                            <p className="mb-0">{item.title}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Order Analytics */}
            <div className="col-xl-6">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-body">
                      <DounutChart />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Latest Orders */}
            <div className="col-xl-6">
              <div className="card">
                <div className="card-header border-0 d-sm-flex d-block">
                  <h2 className="main-title text-black mb-1">Latest Orders</h2>
                </div>
                <div className="card-body pt-3">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Order ID</th>
                        <th>Customer</th>
                        <th>Total</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {latestOrders.map((order, ind) => (
                        <tr key={ind}>
                          <td>{order.orderID}</td>
                          <td>{order.customer}</td>
                          <td>{order.total}</td>
                          <td>
                            <span
                              className={`badge ${getStatusClass(
                                order.status
                              )}`}
                            >
                              {order.status}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Status Badge Styling Function
const getStatusClass = (status) => {
  switch (status) {
    case "Completed":
      return "bg-success text-white";
    case "Pending":
      return "bg-warning text-dark";
    case "Processing":
      return "bg-info text-white";
    case "Cancelled":
      return "bg-danger text-white";
    default:
      return "";
  }
};

export default VendorDashboard;
