import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SVGICON } from "../constant/theme";
import { ArrowBack, Check, Cancel } from "@mui/icons-material";
import { Row, Col, Card, Button, Dropdown, ButtonGroup } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";

const ApprovedMerchantKyc = () => {
  const navigate = useNavigate();

  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth()); // Store selected month index (0-based)

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Dummy sales data
  const dummyStats = {
    totalOrders: 134,
    totalSales: 245000, // Naira
    bestSellingProduct: "50kg Rice",
    monthlySalesData: [
      { month: "January", sales: 150000 },
      { month: "February", sales: 200000 },
      { month: "March", sales: 180000 },
      { month: "April", sales: 220000 },
      { month: "May", sales: 190000 },
      { month: "June", sales: 250000 },
      { month: "July", sales: 230000 },
      { month: "August", sales: 210000 },
      { month: "September", sales: 240000 },
      { month: "October", sales: 260000 },
      { month: "November", sales: 270000 },
      { month: "December", sales: 300000 },
    ],
  };

  // Chart Data
  const chartData = {
    labels: dummyStats.monthlySalesData.map((item) => item.month),
    datasets: [
      {
        label: "Sales Amount (₦)",
        data: dummyStats.monthlySalesData.map((item) => item.sales),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  const [data, setData] = useState(
    document.querySelectorAll("#example7_wrapper tbody tr")
  );
  const sort = 8;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  // useEffect(() => {
  //   setData(document.querySelectorAll("#example7_wrapper tbody tr"));
  //   //chackboxFun();
  // }, [test]);

  // Active pagginarion
  // activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="form-head dashboard-head d-md-flex d-block mb-3 align-items-start justify-content-between">
            <div className="buttons">
              <Button
                className="btn btn-light btn-sm"
                type="button"
                onClick={() => navigate(-1)}
              >
                <ArrowBack /> Back
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex d-block mb-1 w-100 align-items-start justify-content-between">
                    <h3 className="me-auto mt-3"> Merchant Information </h3>
                    <div className="back-button">
                      {/* <Button
                        className="btn btn-outline-success"
                        type="button"
                        disabled
                      >
                        Approved
                      </Button> */}
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="card-text">
                    <div className="table-responsive">
                      <div
                        id="example7_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <table
                          id="example5"
                          className="display mb-4 w-100 defaultTable dataTablesCard dataTable no-footer"
                          style={{ minWidth: "845px" }}
                        >
                          <tbody>
                            <tr>
                              <td>
                                <strong>Business Name:</strong>
                              </td>
                              <td> Alayo Ventures </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Contact Person:</strong>
                              </td>
                              <td> OLuwatomisin Adigun </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Email:</strong>
                              </td>
                              <td> alayoventures@gmail.com </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Phone Number:</strong>
                              </td>
                              <td> 08012341234 </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Address:</strong>
                              </td>
                              <td>
                                {" "}
                                Iyemero Farm Settlement, Via Itapaji Ekiti Town{" "}
                              </td>
                            </tr>
                            {/* <tr>
                              <td>
                                <strong>Status:</strong>
                              </td>
                              <td>
                                {" "}
                                <p className="text-success"> Approved </p>{" "}
                              </td>
                            </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Card>
                <Card.Header>
                  <h3 className="me-auto mt-3">
                    Market Statistics - {monthNames[currentMonth]}
                  </h3>
                  <Dropdown className="float-end">
                    <Dropdown.Toggle
                      variant="outline-primary"
                      id="month-filter"
                    >
                      Select Month
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {monthNames.map((month, i) => (
                        <Dropdown.Item
                          key={i}
                          onClick={() => setCurrentMonth(i)}
                        >
                          {month}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Card.Header>
                <Card.Body>
                  <div className="row">
                    <div className="col-md-4">
                      <Card className="text-center shadow-sm">
                        <Card.Body>
                          <h5>Total Orders in {monthNames[currentMonth]}</h5>
                          <h2>{dummyStats.totalOrders}</h2>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-4">
                      <Card className="text-center shadow-sm">
                        <Card.Body>
                          <h5>Total Sales in {monthNames[currentMonth]}</h5>
                          <h2>₦{dummyStats.totalSales.toLocaleString()}</h2>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-4">
                      <Card className="text-center shadow-sm">
                        <Card.Body>
                          <h5>
                            Best-Selling Product in {monthNames[currentMonth]}
                          </h5>
                          <h3>{dummyStats.bestSellingProduct}</h3>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                  <div className="mt-4">
                    <h5>Yearly Sales Overview</h5>
                    <Bar data={chartData} />
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApprovedMerchantKyc;
