import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Nav, Dropdown } from "react-bootstrap";
import { IMAGES } from "../constant/theme";

import DounutChart from "../elements/DounutChart";
import EarningBar from "../elements/EarningBar";
import CountUp from "react-countup";

const progressBlog = [
  {
    image: IMAGES.FoodIcon8,
    title: "Dine-in",
    number: "92%",
    percent1: "602",
    percent2: "92%",
  },
  {
    image: IMAGES.FoodIcon9,
    title: "Takeaway",
    number: "16%",
    percent1: "52",
    percent2: "16%",
  },
  {
    image: IMAGES.FoodIcon10,
    title: "Online",
    number: "5%",
    percent1: "3.1k",
    percent2: "5%",
  },
];

const mediaBlog = [
  {
    image: IMAGES.FoodIcon10,
    title: "Alayo Ventures",
    price: "₦120,000.00",
    item: "250",
  },
  {
    image: IMAGES.FoodIcon12,
    title: "Fayose Food Stores",
    price: "₦114,000.10",
    item: "216",
  },
  {
    image: IMAGES.FoodIcon13,
    title: "Fayemi Food Stores",
    price: "₦110,000.04",
    item: "100",
  },
  {
    image: IMAGES.FoodIcon14,
    title: "Alese Yam Store",
    price: "₦97,000.60",
    item: "50",
  },
];

const recentOrders = [
  {
    image: IMAGES.FoodIcon7,
    orderId: "ORD001",
    product: "5 tubers of yam",
    price: "₦5,000.00",
    status: "ongoing",
    date: "12 Jan 2025 12:30 pm",
  },
  {
    image: IMAGES.FoodIcon9,
    orderId: "ORD002",
    product: "Rice(5kg)",
    price: "₦,000.00",
    status: "ongoing",
    date: "12 Jan 2025 2:30 pm",
  },
  {
    image: IMAGES.FoodIcon7,
    orderId: "ORD003",
    product: "Palm oil (1 litre)",
    price: "₦1,200.00",
    status: "ongoing",
    date: "18 Jan 2025 11:30 am",
  },
  {
    image: IMAGES.FoodIcon5,
    orderId: "ORD004",
    product: "Cocoyam",
    price: "₦50,000.00",
    status: "completed",
    date: "01 Feb 2025 4:44 pm",
  },
  {
    image: IMAGES.FoodIcon6,
    orderId: "ORD005",
    product: "Maize(1 paint)",
    price: "₦50,000.00",
    status: "ongoing",
    date: "12 Jan 2025 3:21 pm",
  },
];

const FrontDashbaord = () => {
  const [userTypeId, setUserTypeId] = useState(null);

  useEffect(() => {
    const userData = sessionStorage.getItem("userData");
    let user = null;

    let user_type_id = null;

    if (userData) {
      try {
        user = JSON.parse(userData);
      } catch (error) {
        console.error("Failed to parse userData:", error);
      }

      user_type_id = user.user_type_id;
      setUserTypeId(user_type_id);
    }
  }, []);

  console.log("user type", userTypeId);

  let widgetBlog = [];

  if (userTypeId == "1" || userTypeId == "2") {
    widgetBlog = [
      { title: "Total Vendors", number: "12", image: IMAGES.Vendor },
      { title: "Total Orders", number: "130", image: IMAGES.Order },
      {
        title: "Transaction value (credit spend)",
        number: "40000",
        image: IMAGES.Money,
      },
      {
        title: "Transaction value (card spend)",
        number: "6780",
        image: IMAGES.FoodIcon1,
      },
    ];
  } else if (userTypeId == "5") {
    widgetBlog = [
      { title: "Total staffs", number: "128", image: IMAGES.Customers },
      { title: "Total Orders", number: "1300", image: IMAGES.Order },
      {
        title: "Transaction value (credit spend)",
        number: "40000",
        image: IMAGES.Money,
      },
      {
        title: "Transaction value (card spend)",
        number: "6780",
        image: IMAGES.Money,
      },
    ];
  } else {
    widgetBlog = [
      { title: "Total Products", number: "86", image: IMAGES.FoodIcon1 },
      { title: "Total Orders", number: "1300", image: IMAGES.Order },
      { title: "Pending Orders", number: "15", image: IMAGES.Order },
      {
        title: "Transaction value",
        number: "25000",
        image: IMAGES.Money,
      },
    ];
  }

  return (
    <>
      <div className="content-wrapper">
        {/* <!-- row --> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6">
              <div id="user-activity" className="card">
                <Tab.Container defaultActiveKey="Monthly">
                  <div className="card-header border-0 pb-0 d-sm-flex d-block">
                    <div>
                      <h2 className="main-title mb-1">Earnings</h2>
                    </div>
                    <div className="card-action card-tabs mt-3 mt-sm-0">
                      <Nav as="ul" className="nav nav-tabs" role="tablist">
                        <Nav.Item as="li" className="nav-item">
                          <Nav.Link eventKey="Monthly">Monthly</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link eventKey="Weekly">Weekly</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link eventKey="Today">Today</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </div>
                  <div className="card-body">
                    <Tab.Content id="myTabContent">
                      <Tab.Pane eventKey="Monthly">
                        <EarningBar dataActive={0} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="Weekly">
                        <EarningBar dataActive={1} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="Today">
                        <EarningBar dataActive={2} />
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Tab.Container>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="row">
                {widgetBlog.map((item, ind) => {
                  return (
                    <div className="col-sm-6" key={ind}>
                      <div className="widget-card-1 card">
                        <div className="card-body">
                          <div className="media">
                            <img
                              src={item.image}
                              alt=""
                              className="me-4"
                              width="80"
                            />
                            <div className="media-body">
                              <h3 className="mb-sm-3 mb-2 text-black">
                                <CountUp
                                  className="counter ms-0"
                                  end={item.number}
                                  duration={5}
                                />
                              </h3>
                              <p className="mb-0">{item.title}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* <div className="col-xl-6">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-body">
                      <DounutChart />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-xl-6">
              <div className="row">
                <div className="col-xl-12">
                  {(userTypeId === 1 || userTypeId === 2) && (
                    <div className="card">
                      <div className="card-header border-0 d-sm-flex d-block">
                        <div>
                          <h2 className="main-title text-black mb-1">
                            Top Selling Vendors
                          </h2>
                        </div>
                      </div>
                      <div className="card-body pt-3">
                        {mediaBlog.map((data, ind) => (
                          <div
                            className="media mb-3 pb-3 items-list-2 align-items-center"
                            key={ind}
                          >
                            <Link to={"#"}>
                              <img
                                className="img-fluid rounded me-3"
                                width="85"
                                src={data.image}
                                alt="DexignZone"
                              />
                            </Link>
                            <div className="media-body col-6 px-0">
                              <h3 className="mt-0 mb-3 sub-title">
                                {data.title}
                              </h3>
                              <span className="font-w500 mb-3">
                                {data.item} times
                              </span>
                            </div>
                            <div className="media-footer align-self-center ms-auto d-block align-items-center d-sm-flex">
                              <h3 className="mb-0 font-w600 text-secondary">
                                {data.price}
                              </h3>
                              <Dropdown className="dropdown ms-3 ">
                                <Dropdown.Toggle
                                  type="button"
                                  as="div"
                                  className="btn btn-secondary sharp tp-btn-light i-false"
                                  data-toggle="dropdown"
                                >
                                  <svg
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      ></rect>
                                      <circle
                                        fill="#000000"
                                        cx="5"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="12"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                      <circle
                                        fill="#000000"
                                        cx="19"
                                        cy="12"
                                        r="2"
                                      ></circle>
                                    </g>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                                  <Link to={"#"} className="dropdown-item">
                                    Edit
                                  </Link>
                                  <Link to={"#"} className="dropdown-item">
                                    Delete
                                  </Link>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="col-xl-12">
                {userTypeId == "3" && (
                  <div className="card">
                    <div className="card-header">
                      <h5>Recent Orders</h5>
                    </div>
                    <div className="card-body">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th>Order ID</th>
                            <th>Product</th>
                            <th>Price</th>
                            <th>Status</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {recentOrders.map((order, index) => (
                            <tr key={index}>
                              <td>
                                <img
                                  src={order.image}
                                  alt={order.product}
                                  width="40"
                                  height="40"
                                />
                              </td>
                              <td>{order.orderId}</td>
                              <td>{order.product}</td>
                              <td>{order.price}</td>
                              <td>
                                <span
                                  className={`badge ${
                                    order.status === "completed"
                                      ? "bg-success"
                                      : "bg-warning"
                                  }`}
                                >
                                  {order.status}
                                </span>
                              </td>
                              <td>{order.date}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FrontDashbaord;
