import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  ArrowBack,
  Check,
  Cancel,
  Edit,
  CloudUpload,
  Add,
} from "@mui/icons-material";
import { Button, Modal, Form } from "react-bootstrap";
import { IMAGES, SVGICON } from "../../constant/theme";

const tableDataBlog = [
  {
    image: IMAGES.Rice,
    title: "50kg Rice",
    price: "₦98000.00",
    quantity: "75",
  },
  {
    image: IMAGES.GroudnutOil,
    title: "Kings Groundnut Oil",
    price: "₦2800.00",
    quantity: "175",
  },
  {
    image: IMAGES.Beans,
    title: "Oloyin Beans (per derica)",
    price: "₦1000.00",
    quantity: "63",
  },
  {
    image: IMAGES.Semolina,
    title: "Honeywell Semolina (1kg)",
    price: "₦2100.00",
    quantity: "99",
  },
  {
    image: IMAGES.Semovita,
    title: "Golden Penny Semovita (1kg)",
    price: "₦1850.00",
    quantity: "55",
  },
  {
    image: IMAGES.Elubo,
    title: "Black Elubo (Paint)",
    price: "₦2500.00",
    quantity: "53",
  },
  {
    image: IMAGES.Yam,
    title: "Yam (big size)",
    price: "₦3300.00",
    quantity: "35",
  },
  {
    image: IMAGES.Corn,
    title: "Corn (Paint Size)",
    price: "₦4200.00",
    quantity: "22",
  },
];

const VendorProduct = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const handleUploadClose = () => setShowUploadModal(false);
  const handleUploadShow = () => setShowUploadModal(true);

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="form-head dashboard-head d-md-flex d-block mb-4 align-items-start">
            {/* <Button
              className="btn btn-light btn-sm me-2"
              onClick={() => navigate(-1)}
            >
              <ArrowBack /> Back
            </Button> */}
            <h2 className="dashboard-title me-auto">Vendor Products</h2>
            <div className="d-flex gap-2">
              <Button className="btn btn-primary btn-sm" onClick={handleShow}>
                <Add /> Add Product
              </Button>
              <Button
                className="btn btn-secondary btn-sm"
                onClick={handleUploadShow}
              >
                <CloudUpload /> Upload Data
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>ITEM NAME</th>
                      {/* <th>CATEGORY</th> */}
                      <th>PRICE</th>
                      <th>QUANTITY</th>
                      <th>STATUS</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableDataBlog.map((data, ind) => (
                      <tr key={ind}>
                        <td>
                          <Link to={"#"}>
                            <img
                              className="img-fluid rounded me-3"
                              width="50"
                              src={data.image}
                              alt="DexignZone"
                            />
                          </Link>
                          <strong>{data.title}</strong>
                        </td>
                        {/* <td>Raw Food</td> */}
                        <td>{data.price}</td>
                        <td>{data.quantity}</td>
                        <td>
                          <p className="text-success">Approved</p>
                        </td>
                        <td>
                          <Button className="btn btn-warning btn-sm me-2">
                            <Edit /> Edit
                          </Button>
                          <Button className="btn btn-outline-danger btn-sm">
                            <Cancel /> Disable
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Product Modal */}

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Product Name</Form.Label>
              <Form.Control type="text" placeholder="Enter product name" />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>Price</Form.Label>
              <Form.Control type="number" placeholder="Enter price" />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>Quantity</Form.Label>
              <Form.Control type="number" placeholder="Enter quantity" />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control type="text" placeholder="product description" />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>Upload Product Image</Form.Label>
              <Form.Control type="file" accept="image/*" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Save Product</Button>
        </Modal.Footer>
      </Modal>

      {/* Upload Data Modal */}
      <Modal show={showUploadModal} onHide={handleUploadClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Product Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Upload CSV File</Form.Label>
              <Form.Control type="file" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleUploadClose}>
            Close
          </Button>
          <Button variant="primary">Upload</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VendorProduct;
