import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Form, Offcanvas } from "react-bootstrap";
import { SVGICON } from "../constant/theme";

const ministries = [
  "Ministry of Education",
  "Ministry of Health",
  "Ministry of Finance",
];

// Dummy Users List
const initialUsers = [
  {
    fullName: "John Doe",
    email: "john@example.com",
    role: "super_admin",
    ministry: "N/A",
  },
  {
    fullName: "Jane Smith",
    email: "jane@example.com",
    role: "ministry_admin",
    ministry: "Ministry of Health",
  },
  {
    fullName: "Vendor One",
    email: "vendor1@example.com",
    role: "vendor",
    ministry: "N/A",
  },
];

const AccessControl = () => {
  const [users, setUsers] = useState(initialUsers);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newUser, setNewUser] = useState({
    fullName: "",
    email: "",
    role: "vendor",
    ministry: "",
  });

  const userData = sessionStorage.getItem("userData");
  let user = null;

  if (userData) {
    try {
      user = JSON.parse(userData);
    } catch (error) {
      console.error("Failed to parse userData:", error);
    }
  }

  // Handle input change in form
  const handleInputChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  // Create new user
  const handleCreateUser = (e) => {
    e.preventDefault();
    setUsers([...users, newUser]);
    setShowModal(false);
  };

  // Open user details
  const handleViewDetails = (user) => {
    setSelectedUser(user);
    setShowDetails(true);
  };

  // Delete User
  const handleDeleteUser = (index) => {
    const updatedUsers = users.filter((_, i) => i !== index);
    setUsers(updatedUsers);
  };

  // Filter Users by Search Query
  const filteredUsers = users.filter(
    (user) =>
      user.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="content-wrapper">
      <div className="container-fluid">
        {/* Header */}
        <div className="form-head dashboard-head d-md-flex d-block mb-5 align-items-start">
          <h6 className="dashboard-title me-auto">ACCESS CONTROL</h6>

          {/* Search Bar */}
          <div className="input-group search-area">
            <input
              type="text"
              className="form-control"
              placeholder="Search users..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <span className="input-group-text">
              <Link to={"#"}>
                <i className="flaticon-381-search-2"></i>
              </Link>
            </span>
          </div>

          {/* Create User Button */}
          <button
            className="btn btn-primary ms-3"
            onClick={() => setShowModal(true)}
          >
            Add New User
          </button>
        </div>

        {/* Users Table */}
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <table className="display mb-4 w-100 defaultTable dataTable">
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Ministry</th>
                    <th className="text-end">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.map((user, index) => (
                    <tr key={index}>
                      <td>
                        <strong>{user.fullName}</strong>
                      </td>
                      <td>{user.email}</td>
                      <td>
                        {user.role === "super_admin"
                          ? "Super Admin"
                          : user.role === "ministry_admin"
                          ? "Ministry Admin"
                          : "Vendor"}
                      </td>
                      <td>
                        {user.role === "ministry_admin" ? user.ministry : "N/A"}
                      </td>
                      <td className="text-end">
                        <Button
                          variant="info"
                          className="me-2"
                          onClick={() => handleViewDetails(user)}
                        >
                          <span style={{ fill: "white", color: "white" }}>
                            View
                          </span>
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => handleDeleteUser(index)}
                        >
                          <span style={{ fill: "white", color: "white" }}>
                            Del
                          </span>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Add User Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleCreateUser}>
            <Form.Group className="mb-3">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                value={newUser.fullName}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={newUser.email}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Role</Form.Label>
              <Form.Select
                name="role"
                value={newUser.role}
                onChange={handleInputChange}
              >
                <option value="super_admin">Super Admin</option>
                <option value="ministry_admin">Ministry Admin</option>
                <option value="vendor">Vendor</option>
              </Form.Select>
            </Form.Group>

            {/* Show Ministry Dropdown only for Ministry Admins */}
            {newUser.role === "ministry_admin" && (
              <Form.Group className="mb-3">
                <Form.Label>Ministry</Form.Label>
                <Form.Select
                  name="ministry"
                  value={newUser.ministry}
                  onChange={handleInputChange}
                >
                  <option value="">Select Ministry</option>
                  {ministries.map((ministry, index) => (
                    <option key={index} value={ministry}>
                      {ministry}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}

            {newUser.role === "vendor" && (
              <Form.Group className="mb-3">
                <Form.Label>Business Name</Form.Label>
                <Form.Control
                  type="text"
                  name="businessName"
                  value={newUser.businessName}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            )}

            <Button variant="primary" type="submit" className="w-100">
              Save User
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* View User Details Offcanvas */}
      <Offcanvas
        show={showDetails}
        onHide={() => setShowDetails(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>User Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {selectedUser ? (
            <>
              <p>
                <strong>Full Name:</strong> {selectedUser.fullName}
              </p>
              <p>
                <strong>Email:</strong> {selectedUser.email}
              </p>
              <p>
                <strong>Role:</strong>{" "}
                {selectedUser.role === "super_admin"
                  ? "Super Admin"
                  : selectedUser.role === "ministry_admin"
                  ? "Ministry Admin"
                  : "Vendor"}
              </p>
              <p>
                <strong>Ministry:</strong>{" "}
                {selectedUser.role === "ministry_admin"
                  ? selectedUser.ministry
                  : "N/A"}
              </p>
            </>
          ) : (
            <p>No user selected.</p>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default AccessControl;
